.HR-content {
    flex: 1;
    z-index: 1; /* Ensure the content is above the background image */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white; /* White text for readability on the image */
    position: absolute;
    top: 22%;
    left: 19%;
    transform: translateX(-50%); /* Center the content horizontally */
  }
  
  .HR-paragraph1, .HR-nav-heading{
    width: 100%;
    max-width: 490px; /* Adjust as needed for your layout */
   text-align: left;
   margin-top: -10px; /* Remove top margin to align with heading */
  
  }
  .HR-nav-heading {
    color: #FFFAFA;
    font-size: 66px;
    font-family: Poppins;
    font-weight: 700;
    line-height: 83px;
    word-wrap: break-word;
    white-space: nowrap;
  
  }
  .HR-paragraph1{
    color: #FEFEFF; /* Ensure paragraph text color matches heading */
    margin-top: 30px; /* Remove top margin to align with heading */
font-size: 18px;
font-family: Poppins;
font-weight: 400;
white-space: nowrap;
  }
  .HR-overlay-content {
    position: relative;
    top: 27vh; /* Adjust positioning as needed */
     left: 15%; 
    transform: translate(-10%, -20%);
    text-align: left;
    color: #fff;
    margin-bottom: 7vh;
  }
  .HR-heading2 {
    margin-bottom: 10px; /* Add space between heading and containers */
    text-align: center;
    margin-top: 20px;
    font-size: 48px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;
word-wrap: break-word
  }
  
  .HR-heading3 {
    margin-bottom: 10px; /* Add space between heading and containers */
    text-align: left;
    font-size: 38px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;
word-wrap: break-word
  
  
  }
  .HR-heading-part11 {
    color: #070707; /* First color */
    font-weight: 700;
  }
  
  .HR-heading-part22 {
    color: #2a4ef0; /* Second color */
    font-weight: 700;
  }
  .HR-heading4 {
    margin-bottom: 10px; /* Add space between heading and containers */
    text-align: left;
    font-size: 38px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;
word-wrap: break-word;
margin-left: 60px;
  }
  .HR-heading5 {
    margin-top: 40px;
    margin-bottom: 10px; /* Add space between heading and containers */
    text-align: left;
    font-size: 38px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;
word-wrap: break-word;
margin-left: 95px;
  }
  .HR-paragraph2{
    font-size: 18px; /* Adjust as needed */
    color:  rgba(0, 0, 0, 0.60);
    font-weight: 400;
margin-bottom: 50px;
    text-align: center;
font-family: Poppins;
word-wrap: break-word
  }
  .HR-paragraph3{
    font-size: 18px; /* Adjust as needed */
    color:  rgba(0, 0, 0, 0.60);
    font-weight: 400;
    margin: 25px 0;
font-family: Poppins;
text-align: justify; /* Optional: Justify the text */
word-wrap: break-word; /* Prevents long words from overflowing */
margin: 1em 0; /* Adds spacing between paragraphs */
  }
  .HR-paragraph4{
    font-size: 18px; /* Adjust as needed */
    color: rgba(0, 0, 0, 0.60);
    font-weight: 400;
    text-align: justify; /* Optional: Justify the text */
    word-wrap: break-word; /* Prevents long words from overflowing */
    overflow-wrap: break-word; /* Ensures proper word wrapping */
    margin: 1em 0; /* Adds spacing between paragraphs */
    font-family: Poppins;
    line-height: 1.5; /* Ensures proper line spacing */
  }
  
  .HR-paragraph3 {
 
}
.HR-paragraph5{
    font-size: 18px; /* Adjust as needed */
    color:  rgba(0, 0, 0, 0.60);
    font-weight: 400;
margin-bottom:30px ;
    text-align: justify;
    margin-left: 100px;
font-family: Poppins;
word-wrap: break-word
  }
  @media (min-width: 1440px) and (max-width: 1910px) {
    .HR-overlay-content{
     margin-top: 80px;
     margin-bottom: -90px;
    }
    .HR-heading5 {
        margin-left: 77px;
    }

    .HR-paragraph5{
margin-left: 77px;
    }
     
     }