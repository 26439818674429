.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 490px;
    margin-top: 320px;
    justify-content: center;
    margin-bottom: 40px;
    position: relative;
    bottom: 80px;
  }
  
  .icon-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: flex-start; */
    width: 340px;
    height: 225px;
    flex-shrink: 0;
    padding: 15px;
    /* margin-left: 10px;
    margin-right: 10px; */
    border: 1px solid #e9e4e4;
    border-radius: 8px;
    background-color: #fff;
    transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
    /* box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.11); */
  }
  
  .icon {
    position: relative; /* Positions the icon relative to the container */
    /* top: 10px; /* Adjust the distance from the top */
    /* left: 10px;  */
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
  .heading {
    margin: 0;
    padding: 0; 
    text-align: left;
    color: black;
font-size: 20px;
font-family: Poppins;
font-weight: 700;
word-wrap: break-word
  
  }
  .icon-container:hover .heading {
    color: #fff; /* Change text color to white on hover */
  }
  .text {
    color: rgba(0, 0, 0, 0.60);
    margin: 0;
    padding-top: 5px; 
    text-align: left;
    max-width: 100%;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
   
  }
  .icon-container:hover {
    background-color: #3498db; /* Primary color on hover */
    border-color: #2980b9; /* Darker shade of primary color */
    transform: scale(1.05); /* Slightly increase the size */
  }
  .icon-container:hover .text {
    color: #fff; /* Change text color to white on hover */
  }
  /* Base styles for the grid container */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

/* Styles for each icon container */
.icon-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

.icon {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.heading {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.text {
  font-size: 0.9rem;
  color: #666;
}

/* Media Queries */

/* Mobile devices */
@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: 1fr;
    padding: 10px;
    margin-top: 250px;
    
  }

  .icon-container {
    padding: 15px;

  }

  .heading {
    font-size: 1rem;
  }

  .text {
    font-size: 0.8rem;
  }
}

/* Tablet devices */
@media (min-width: 768px) and (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .icon-container {
    padding: 18px;
  }

  .heading {
    font-size: 1.1rem;
  }

  .text {
    font-size: 0.85rem;
  }
}

/* Large screens */
@media (min-width: 1025px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .icon-container {
    padding: 20px;
  }

  .heading {
    font-size: 1.3rem;
  }

  .text {
    font-size: 0.9rem;
  }
}
