/* General Footer Styles */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Disable horizontal scrollbar */
}


  .RD-image-container2{
    width: 92vw;
    height: 100%;
    max-height:91vh ;
    position: relative;
    padding-top: 190px; /* Adjust this value to increase the space at the top */
    margin-top: 70px;
    border-radius: 90px;
    
  }
  .RD-my-image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 90px;
    z-index: -1; /* Ensure the image stays in the background */
  }.RD-overlay-content {
  margin-top: 70px;
  z-index: 1; /* Ensures the content is above the background image */
 
  text-align: center;
  
  }
  .RD-heading3 {
    margin: 0 0 20px 0; /* Adjust margin to control spacing */
    display: inline-block; /* Keep the heading inline */
    white-space: nowrap; /* Prevent line break */
    font-size: 48px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;
position: relative;
top: -220px;
/* margin-top: -30px; */
  }
  .RD-heading-part11, .RD-heading-part22 {
    display: inline; /* Ensure the spans are inline */
  }
  
  .RD-heading-part11 {
    color: #070707;
    font-weight: 700;
    white-space: nowrap;
  }
  
  .RD-heading-part22 {
    color: #2a4ef0;
    font-weight: 700;
    white-space: nowrap;
  }
  .form-container {
    max-width: 1000px; /* Adjust the width as needed */
    margin: -190px auto; /* Center the form */
    padding: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    margin-top: -50px;
  
  }
  
  .form-row {
    display: flex;
    margin-bottom: 20px; /* Transparent gap between fields */
    gap: 35px;
  }
  
  input,
  textarea {
    width: 100%; /* Make fields wider */
    height: 60px; /* Increase the height of input fields */
    padding: 15px; /* Increase padding for larger fields */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1.2em; /* Increase font size for better readability */
    color: black;
    font-weight: 500;
    box-sizing: border-box;
  }
  
  textarea {
    resize: none;
    width: 1000px;
  }
  
  input::placeholder,
  textarea::placeholder {
    color: black;
    text-align: left;
    
  }
  .form-row textarea {
    height: 280px; /* Make the message field large */
    resize: none; /* Prevent resizing */
  }
  
  input::placeholder,
  textarea::placeholder {
    color: black; /* Placeholder text color */
    text-align: left; /* Align placeholder text to the left */
  }
  
  .submit-row {
    display: flex;
    justify-content: center; /* Center the submit button */
  }
  /* Keyframes for the expand-shrink-jump animation */
@keyframes expand-shrink-jump {
  0% {
      transform: scale(1); /* Initial size */
  }
  30% {
      transform: scaleX(1.3) scaleY(1.3); /* Expand width, shrink height */
  }
  50% {
      transform: scaleX(0.8) scaleY(0.8); /* Shrink width, expand height */
  }
  80% {
      transform: scale(1.1) translateY(-5px); /* Jump effect and expand */
  }
  100% {
      transform: scale(1); /* Return to normal size */
  }
}
  .submit-btn {
    padding: 15px 45px;
    background-color: #fbfcfd; /* Primary button color */
    color: #007bff;
    width: 170px;
    border-radius: 30px;
    font-size: 1.2em;
    border: 2px #007bff solid;
    transition: all 0.3s ease-in-out; /* Smooth transition */
    cursor: pointer;
    animation: none; /* Initially no animation */
  }

  /* Apply the animation when hovered */
.submit-btn:hover {
  animation: expand-shrink-jump 0.8s ease-in-out;
  background-color: #0056b3; /* Darken on hover */
color: #ffffff;
}

/* Apply the animation when clicked */
.submit-btn:active {
  animation: expand-shrink-jump 0.8s ease-in-out;
}
.success-message {
  color: green;
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
}

/* Popup notification styles */
.popup-message {
  position: fixed;
  top: 10px;  /* Position at the top */
  left: 50%;
  transform: translateX(-50%);
  background-color: #4CAF50;  /* Green background */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;  /* Ensure it's on top of other content */
  animation: fadeIn 0.5s, fadeOut 0.5s 4.5s;  /* Fade in and out animation */
}

/* Fade-in and fade-out animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


@media (min-width: 1440px) and (max-width: 1910px) {
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrollbar */
  }

  .RD-image-container2 {
    margin-top: 20px;
    max-height: 900px;
    overflow: hidden; /* Prevent vertical overflow if content exceeds height */
  }

  .form-container {
    max-width: 100%; /* Ensure no horizontal overflow */
    overflow-y: auto; /* Allow vertical scrolling only */
    margin: 0 auto; /* Center content if necessary */
    padding: 0 16px; /* Optional for consistent spacing */
    margin-top: -270px;
  }

  .RD-heading3 {
    margin-top: -30px;
    margin-bottom: 70px;
  }

  .contact-form {
    width: 100%;
    max-width: 600px; /* Adjust form width for responsiveness */
    margin: 0 auto; /* Center the form */
  }
}
