html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.navbar {
  position: relative;
}
.btn-group1:hover .dropdown-menu,
.btn-group1.active .dropdown-menu {
  display: block;
}
.btn-group2:hover .dropdown-menu,
.btn-group2.active .dropdown-menu {
  display: block;
}
.btn-group1{
  margin-left: 420px;
  position: relative; /* For dropdown positioning */
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-group2{
  margin-left: 30px;
  position: relative; /* For dropdown positioning */
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn1,
.btn2 {
  margin: 0 10px; /* Add spacing between buttons */
}
.btn1 {
  background-color: transparent; /* Primary blue color */
  color: #0D0E0E; /* White text */
  border: none;
  margin-right: -30px;
  font-size: 20px;
  padding: 10px 20px;
  transition: background-color 0.3s ease; /* Smooth transition */
  position: relative; /* Ensure dropdown is correctly positioned */
  z-index: 10; /* Higher than other content */
}

.btn1:hover {
  background-color: linear-gradient(313deg, #c9d3e5, #f2f4f9 88%, #fff);; /* Darker blue color on hover */
}
.dropdown-item {
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  display: block;
  font-size: 17px;
}
.dropdown-item:hover {
  background-color: #007BFF;
  color: white;
}


.navbar-container {
  max-width: 1200px; /* Set a max width to prevent spreading */
  margin: 0 auto; /* Center the navbar container horizontally */
  width: 100%; /* Ensure it takes up full width */
}
.custom-navbar {
  display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 20px;
    position: fixed; /* Makes the navbar fixed at the top */
    top: 0; /* Sticks it to the top */
    left: 0; /* Aligns it to the left */
    right: 0; /* Aligns it to the right */
    z-index: 1000; /* Ensures it's above other content */
    background-color: white; /* Keep a background color to prevent transparency */
    box-sizing: border-box; /* Ensure padding and border are included in the width */
}
  .navbar-nav .nav-link,
.navbar-nav{
  margin-right: 20px; /* Adjust this value as needed */
}
  
  .navbar-logo {
    height: 40px; 
  margin-left: 105px;
  }
  
  .navbar-nav {
    display: flex;
    align-items: center;
  }
  
  .navbar-text1 {
    margin-left: 10px;
    cursor: pointer;
    font-size: 20px; 
  }
  
  .navbar-text1:hover {
    text-decoration: underline;
  }
 
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0.5rem;
    margin: 0;
    z-index: 1000;
    width: max-content;
    border-radius: 20px;
    background: linear-gradient(313deg, #c9d3e5, #f2f4f9 88%, #fff);
    transition: 0.3s;
  }
 

   /* Optional: Style links on hover */
   .dropdown-menu a:hover {
    display: block;
    opacity: 1; 
    visibility: visible; 
    color: #096BD9;
    background-color: transparent;
  }

  /* Target the dropdown toggle (title) specifically */
#basic-nav-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
}

/* Adjust the position or size of the icon */
#basic-nav-dropdown .dropdown-toggle::after {
  margin-left: 8px; /* Adjust space between text and icon */
  font-size: 0.8em; /* Adjust icon size */
  transform: translateY(2px); /* Adjust vertical position */
}

/* Optional: change the icon style when dropdown is open */
#basic-nav-dropdown.show .dropdown-toggle::after {
  transform: rotate(180deg) translateY(8px);; /* Rotate icon when dropdown is open */
}

.animated-btn {
  width: 140px;
  height: 50px;
  background-color: white; /* Set background color to white */
  color: #096BD9; /* Set text color to primary blue */
  border: 2px solid #096BD9; /* Add blue border */
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
margin-right: 200px;
  font-size: 16px;
}

/* Hover effect with keyframes */
.animated-btn:hover {
  animation: expand-shrink-jump 0.6s ease forwards;
}

/* Keyframe animation for expanding, shrinking, and jumping */
@keyframes expand-shrink-jump {
  0% {
      transform: scale(1); /* Initial size */
  }
  30% {
      transform: scaleX(1.3) scaleY(1.3); /* Expand width, shrink height */
  }
  50% {
      transform: scaleX(0.8) scaleY(0.8); /* Shrink width, expand height */
  }
  80% {
      transform: scale(1.1) translateY(-5px); /* Jump effect and expand */
  }
  100% {
      transform: scale(1); /* Return to normal size */
  }
}

/* Optional: You can keep this margin-left auto if required */
.ml-auto {
  margin-left: auto;
}

  
  .container-11 {
    display: flex;
    width: 100%; /* Adjust width as needed */
    height: 65vh; /* Adjust height as needed */
    
    overflow: hidden; /* To ensure the rounded corners affect the child elements */
  }
  .navbar-text .nav-link {
    text-decoration: none; /* Remove underline by default */
    color: #0D0E0E; /* Inherit color from parent or set a specific color */
    padding: 0 0px; /* Optional: add some padding */
white-space: nowrap;
font-size: 20px;
margin-left: 30px;
position: relative; /* To position the underline */
  }
  .nav-link1 {
    text-decoration: none; /* Remove underline by default */
   font-size: 20px;
   margin-left: 60px;
    color: #0D0E0E; /* Inherit color from parent or set a specific color */
    padding: 0px 0px; /* Optional: add some padding */
    position: relative; /* To position the underline */
  }
 .navbar-text .nav-link1:hover::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px; /* Adjust the distance from the text */
  height: 2px; /* Thickness of the underline */
  background-color: #0D0E0E; /* Color of the underline */
  transition: width 0.3s ease; /* Optional transition for animation */
  width: 100%; /* Full-width underline */
}
.navbar-text .nav-link1, 
.navbar-text .request-demo {
  position: relative;
  text-decoration: none;
  color: #0D0E0E;
}
.navbar-text .nav-link1:hover::after,
.navbar-text .request-demo:hover::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px; /* Distance from the text */
  height: 2px; /* Thickness of the underline */
  background-color: #0D0E0E; /* Color of the underline */
  transition: width 0.3s ease;
  width: 100%; /* Full-width underline */
}
.navbar-text .nav-link:hover::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px; /* Adjust the distance from the text */
  height: 2px; /* Thickness of the underline */
  background-color: #0D0E0E; /* Color of the underline */
  transition: width 0.3s ease; /* Optional: Smooth animation for underline */
  width: 100%; /* Full-width underline */
}
 .navbar-expand-lg .navbar-collapse{
  /* gap: 40px; */
 } 
 .navbar-expand-lg .navbar-nav .nav-link{
  color:#0D0E0E;
 }

  .container-12 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; /* Full viewport height */
    min-height: 70vh;
    width: 100%;
    overflow: hidden; /* Ensure no overflow from content */
    
    }
    
    .background-image {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 99vw;
      height: 100%;
      object-fit: cover; /* Ensure the image covers the entire container */
      transform: translate(-50%, -50%); /* Center the image */
      z-index: -1; /* Place the background image behind other content */
  border-bottom-left-radius: 80px; /* Adjust the radius as needed */
  border-bottom-right-radius: 80px; /* Adjust the radius as needed */
  }
  /* Responsive adjustments */
@media (max-width: 768px) {
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .background-image {
      width: 150%; /* Adjust scaling for smaller screens */
      height: 150%;
  }
}
/* Styles for larger laptops (1440px to 1600px) */
@media (min-width: 1440px) and (max-width: 1910px) {
  .navbar-container {
    padding: 20px 40px; /* Adjust padding for larger laptops */
  }

  .navbar-logo {
    width: 180px; /* Increase logo size for large laptops */
    margin-left: -40px;
  }

  .navbar-text1 {
    width: 220px; /* Increase text logo size */
    margin-left: -70px;
  }
  .nav-link1 {
    font-size: 20px; /* Adjust font size for nav links */
  }

  .animated-btn {
    padding: 8px 20px; /* Adjust button size */
    font-size: 16px;
  }

  /* Dropdown font size and spacing */
  .dropdown-menu {
    font-size: 15px; /* Slightly larger font */
  }
 
}
