.C-content {
    flex: 1;
    z-index: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    position: absolute;
    top: 22%;
    left: 20%;
    transform: translateX(-50%);
  }
  
  .C-paragraph1, .C-nav-heading {
    width: 100%;
    max-width: 490px;
    text-align: left;
    margin-top: 10px;
  }
  
  .C-nav-heading {
    font-size: 3.5em;
    font-weight: 700;
    color: #FFFAFA;
    font-size: 66px;
    font-family: Poppins;
    font-weight: 700;
    line-height: 83px;
    word-wrap: break-word
  }
  
  .C-paragraph1 {
    font-size: 1.1em;
    color: #FEFEFF;
    margin-top: 30px;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word;
white-space: nowrap;
  }
  
  .C-overlay-content1 {
    position: relative;
  padding: 40px;
  top: 3vh;
  z-index: 1; /* Ensures the content is above the background image */
  text-align: center;

  
  }
  .C-heading3 {
    margin: 20 0 0px 0; /* Adjust margin to control spacing */
    display: inline-block; /* Keep the heading inline */
    white-space: nowrap; /* Prevent line break */
    font-size: 48px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;
word-wrap: break-word
    
  }
  .C-heading-part11, .C-heading-part22 {
    display: inline; /* Ensure the spans are inline */
  }
  
  .C-heading-part11 {
    color: #070707;
    font-weight: 700;
    white-space: nowrap;
  }
  
  .C-heading-part22 {
    color: #2a4ef0;
    font-weight: 700;
    white-space: nowrap;
  }
  .C-grid1{
    margin-top: -100px;
    padding-left: 240px;
    padding-right: 240px;
  }
  .C-image-container2 {
    display: flex;
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
  
  }
  
  .C-my-image1 {
    position: absolute;
    top: 50px;
    left: 0px;
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    margin-top: -10px;
    border-radius: 8px;
  }
  
  .C-overlay-content2 {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    left: 50vw;
    bottom: 6vh;
  }
  
  .C-heading0 {
    margin-bottom: 10px;
    text-align: left;
    position: relative;
    bottom: 50px;
    left: 15vh;
    font-size: 48px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;
word-wrap: break-word
  
  }
  
  .C-paragraph201 {
    font-size: 18px;
    white-space: nowrap;
    color:    rgba(0, 0, 0, 0.90);
    text-align: left;
    font-weight: 400;
    line-height: 1.5;
    top: 100px;
    right: 510px;
    position: relative;
    text-align: left;
font-family: Poppins;
word-wrap: break-word
  }
  
  .C-dp-image {
    width: 26vw;
    height: auto;
    position: relative;
    right: 88vw;
    top: 8vh;
  }
  .C-gradient-container {
    position: relative;
    width: 100%;
    height: 170vh; /* Adjust this to the size you need */
    background: linear-gradient(to right, rgba(0, 47, 108, 1), rgba(8, 89, 149, 0.8), rgba(3, 92, 181, 0.804), rgb(42, 126, 236));
    display: flex;
    margin-top: 110px;
    /* justify-content: center; */
    align-items: center;
    overflow: hidden;
    bottom:15vh;
    margin-bottom: 30px;
  }
  .C-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.3; /* Reduce the shine of the image */
  }
  .C-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(18, 59, 112, 0.5); /* Slight blue overlay */
    z-index: 1;
  }
  .C-content-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    z-index: 2;
  }
  
  .C-text-content {
    color: white;
    max-width: 50%; /* Adjust according to your layout needs */
    text-align: center;
    position: relative;
    left:36vw ;
    bottom: 210px;
  }
  
  .C-heading41 {
    line-height: 55px;
    margin-top: 1vh;
    color: white;
font-size: 48px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;
word-wrap: break-word
  }

  .C-paragraph2011 {
    font-size: 18px;
    margin-top: 6vh;
    font-weight: 400;
    color: white;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
  }
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    background-size: cover;
  }
   
  .C-container-wrapper1 { 
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between containers */
    position: relative;
    right: 22vw;
    top: 110px;
    width: 60%;
  }
  
  .C-container-box {
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .C-container-box h2 {
    margin: 0 0 10px 0;
    text-align: left;
    color: black;
font-size: 20px;
font-family: Poppins;
font-weight: 700;
word-wrap: break-word
  }
  
  .C-container-box p {
    margin: 0;
    text-align: left;
    color: black;
font-size: 16px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
  }
  .C-text-container1 {
    max-width: 60%;
    text-align: left;
    margin-left: auto;
    position: relative;
    right: 500px;
    top: 80px;
  }
  .C-heading45{
  line-height: 55px;
  position: relative;
  left: -50px;
  bottom: 170px;
  font-size: 48px;
font-family: Outfit;
font-weight: 700;
line-height: 55px;
word-wrap: break-word
  }
  .C-heading-part111 {
    color: #070707;
    font-weight: 700;
  }
  
  .C-heading-part222 {
    color: #2a4ef0;
    font-weight: 700;
  }
  .C-left-side-content {
    width: 90%; /* Adjust width as needed */
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    bottom: 15vh;
    left: -60px;
    
  }
  .C-left-side-content1 {
    width: 90%; /* Adjust width as needed */
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    bottom: 11vh;
    left: -60px;
    
  }
  .C-dp-image11 {
    width: 26vw;
    height: 43vh;
    position: relative;
    right: 1520px;
    bottom: 150px;
  }
  .C-content-block {
    margin-bottom: 10px;
  }
  
  .C-content-heading {
    font-size: 24px;
    margin-bottom: 10px;
    color: black;
font-size: 20px;
font-family: Poppins;
font-weight: 700;
word-wrap: break-word
  }
  
  .C-content-paragraph {
    color:  rgba(0, 0, 0, 0.60);
    margin-bottom: 20px;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
  }
  .C-dp-image131 {
    width: 26vw;
    height: auto;
    position: relative; 
    left: 890px;
    top: -680px;
   
  }
  .C-overlay-content11 {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    left: 1080px;
  top: 20vh;
  }
  .C-image-container21 {
    display: flex;
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    bottom: 50vh;
  
  }
  .C-dp-image1 {
    width: 28vw;
    height: 47vh;
    position: relative;
    right: 78vw;
    bottom: 17vh;
  }
  .C-text-container11 {
    max-width: 60%;
    text-align: left;
    margin-left: auto;
    position: relative;
    right: 25vw;
  bottom: 25vh;
  }
  .C-image-container211 {
    display: flex;
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    bottom: 75vh;
  
  }
  .C-dp-image119 {
    width: 26vw;
    height: 42vh;
    position: relative;
    left: 850px;
    bottom: 510px;
  }
 
  .C-text-container111 {
    max-width: 60%;
    text-align: left;
    margin-left: auto;
    position: relative;
    right: 26vw;
    bottom: 58vh;
  }
  .C-heading451{
  line-height: 55px;
  position: relative;
  left: 1px;
  bottom: 14vh;
  font-size: 48px;
font-family: Poppins;
font-weight: 700;
word-wrap: break-word
  }
  .C-dp-image112 {
    width: 26vw;
    height: auto;
    position: relative; 
    left: 53vw;
    bottom: 51vh;
  }
  .C-content-paragraph12{
    position: relative;
    bottom: 10vh;
    color: black;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
  }
  .C-text-container {
    background-color: #EAF1FD;
    position: relative;
    padding: 20px;
    width: 100%;
    min-height: 90vh; /* Use min-height to ensure enough space without being too large */
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 80px; /* Adjust margin for space between container and footer */
  margin-top: -930px;
  }
  .C-overlay-content {
    position: absolute;
    top: 60px;
    right: 75vh;
    color: #000;
    max-width: 60%;
    text-align: center;
    z-index: 2;
  }
  
  .C-heading4 {
    margin: 0;
    text-align: center;
    font-size: 48px;
font-family: Poppins;
font-weight: 700;
word-wrap: break-word
  }
  
  .C-heading4 {
    font-size: 2.6em; /* Increased font size for better visibility */
    margin-bottom: 10px;
  }
  
  .C-heading-part111 {
    color: #070707;
    font-weight: 700;
  }
  
  .C-heading-part222 {
    color: #2a4ef0;
    font-weight: 700;
  }
  .C-center-page {
    display: flex;
    justify-content: center;
  }
  .C-grid{
    margin-top: 5px;
    padding-left: 50px;
    padding-right: 50px;

  }
  .C-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust columns */
    gap: 0px; /* No space between grid items */
    padding-left: 20px; /* Reset padding */
    padding-right: 20px; /* Reset padding */
    margin-left: 540px; /* If you want to reduce space on the left side */
    margin-right: 515px; /* If you want to reduce space on the right side */
margin-top: -120px;
  }
.C-icon-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 340px;
  height: 225px;
  flex-shrink: 0;
  padding: 15px;
  border: 1px solid #e9e4e4;
  border-radius: 8px;
  background-color: #fff;
  transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
}

.C-icon {
  position: relative; /* Positions the icon relative to the container */
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.C-heading {
  font-size: 20px;
  margin: 0;
  padding: 0; 
  text-align: left;
  color: black;
font-family: Poppins;
font-weight: 700;
word-wrap: break-word

}
.C-icon-container:hover .C-heading {
  color: #fff; /* Change text color to white on hover */
}
.C-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.60);
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  text-align: left;
  word-wrap: break-word;
 
}
.C-icon-container:hover {
  background-color: #3498db; /* Primary color on hover */
  border-color: #2980b9; /* Darker shade of primary color */
  transform: scale(1.05); /* Slightly increase the size */
}
.C-icon-container:hover .C-text {
  color: #fff; /* Change text color to white on hover */
}

.C-Btn-custom1, .C-Btn-custom {
  color: #6EACFB;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  margin-left: 320px;
  width: 150px;
  max-width: 12vw;
  height: 60px;
  transition: background-color 0.3s ease, transform 0.6s ease;
  background: linear-gradient(270deg, #f7f8fa 0%, #ffffFF 100%);
  border: 2px solid #6EACFB; /* Corrected border property */
  margin-top: 10px;
}
.C-Btn-custom {
  margin-top: -80px;
  margin-left: 10px;
}
.C-Btn-custom1{
  margin-top: 30px;
  margin-left: 10px;
}
.C-Btn-custom1:hover, .C-Btn-custom:hover {
  background: linear-gradient(270deg, #096BD9 0%, #0e4d96 100%); /* Change gradient on hover */
  color: #ffffff;
  border: 2px solid #ffffff; /* Border width and color for hover */
  animation: expand-shrink-jump 0.6s ease forwards;
}

/* Keyframe animation for expanding, shrinking, and jumping */
@keyframes expand-shrink-jump {
  0% {
    transform: scale(1); /* Initial size */
  }
  30% {
    transform: scaleX(1.3) scaleY(1.3); /* Expand width, shrink height */
  }
  50% {
    transform: scaleX(0.8) scaleY(0.8); /* Shrink width, expand height */
  }
  80% {
    transform: scale(1.1) translateY(-5px); /* Jump effect and expand */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}
@media (min-width: 1440px) and (max-width: 1910px) {
  .C-overlay-content2{
    margin-left: 190px;
  }
  .C-dp-image{
    margin-left: -220px;
  }
  .C-paragraph201{
    margin-left: -40px;
  }
  .C-gradient-container{
    height: 210vh;
  }
  .C-text-content{
    margin-left: 210px;
    white-space: nowrap;
  }
  .C-container-wrapper1{
    margin-right: 100px;
  }
  .C-text-container1{
    margin-left: 1060px;
  }
  .C-dp-image131{
    margin-left: -80px;
  }
  .C-dp-image1{
    margin-left: -170px;
  }
  .C-left-side-content1{
    margin-left:60px ;
  }
  .C-dp-image119{
    width: 400px;
    height: auto;
  }
  .C-dp-image11{
    margin-left: 100px;
  }
  .C-dp-image112{
    margin-top: -50px;
  }
  .C-text-container{
    margin-bottom: 60px;
    position: relative;
    top: 170px; 
  }
  .C-grid{
    margin-top: -70px
  }
  .C-heading4{
    margin-right:150px ;
  }
  .C-Btn-custom1{

  }
  .C-center-page{
    margin-bottom: 210px;
  }
}