.grid-container2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 320px;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    bottom: 15vh;
  }
  
  .icon-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: flex-start; */
    width: 340px;
    height: 225px;
    flex-shrink: 0;
    padding: 15px;
    /* margin-left: 10px;
    margin-right: 10px; */
    border: 1px solid #f9f9f9;
    border-radius: 8px;
    background-color: #fff;
    transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.11);
  }
  
  .icon {
    position: relative; /* Positions the icon relative to the container */
    /* top: 10px; /* Adjust the distance from the top */
    /* left: 10px;  */
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  .heading {
    margin: 0;
    padding: 0; 
    text-align: left;
    color: black;
font-size: 20px;
font-family: Poppins;
font-weight: 700;
word-wrap: break-word
  
  }
  .icon-container:hover .heading {
    color: #fff; /* Change text color to white on hover */
  }
  .text {
    color:  rgba(0, 0, 0, 0.60);
    margin: 0;
    padding-top: 5px; 
    text-align: left;
    max-width: 100%;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
   
  }
  .icon-container:hover {
    background-color: #3498db; /* Primary color on hover */
    border-color: #2980b9; /* Darker shade of primary color */
    transform: scale(1.05); /* Slightly increase the size */
  }
  .icon-container:hover .text {
    color: #fff; /* Change text color to white on hover */
  }