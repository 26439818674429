html, body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

  .H-content {
    flex: 1;
    z-index: 1; /* Ensure the content is above the background image */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white; /* White text for readability on the image */
    position: absolute;
    top: 10%;
    left: 20%;
    transform: translateX(-50%); /* Center the content horizontally */
}
 
  .H-paragraph, .H-nav-heading, .H-paragraph1{
    width: 100%;
    max-width: 100%; 
    margin: 10px 60px;
    text-align: left;
    align-items: left;
  }
  .H-paragraph, .H-paragraph1{
    color: #fff;
font-size: 15px;
font-family: Poppins;
font-weight: 400;

  }
  .H-paragraph1{
font-size: 18px;
white-space: nowrap;
position: relative;
left:10px

  }
  
  .H-nav-heading {
    font-size: 4em;
    color: #FFFAFA;
    font-size: 80px;
font-family: Poppins;
font-weight: 700;
line-height: 83px;
white-space: nowrap;
  }
/* Container for the dropdown */
.dropdown {
  width: 100%;
   height: 100%;
    border-radius: 6px;
     border: 1px rgba(255, 255, 255, 0.29);
     position:relative;
     left: 70px;
}

.btn-secondary.dropdown-toggle {
  background-color: transparent; /* Transparent background */
  border: 1px solid #ccc; /* Border color */
  padding: 8px 16px; /* Padding inside the button */
  width: 500px; /* Set your desired width */
  height: 50px; /* Set your desired height */
  border-radius: 4px; /* Rounded corners */
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color:rgba(255, 255, 255, 0.80);
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-;
transition: color 0.3s ease; /* Smooth transition for color change */
}
.btn-secondary.dropdown-toggle:hover {
  color: #096BD9; /* Primary color on hover */
  background-color: #ffffff;
}

.btn-secondary.dropdown-toggle::after {
  margin-left: 8px;
  font-size: 25px;
}


.dropdown-menu {
  border-radius: 15px;
  border: 2px solid #ccc; /* Border color for dropdown menu */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Dropdown shadow */
  width: 15vw; /* Ensure the menu matches button width */
}
.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0; /* Hover background color */
}
.Home1-grid{
margin-top: -140px;
}
.H-main-container1, .main-container2, .H-gradient-container, .Home-grid {
  max-width: 2000px;
  width: 100%;
  margin: 0 auto; /* Center container */
  padding: 0 0; /* Add some padding to ensure it doesn't touch edges */
}

.Home-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 370px;
  padding-right: 470px;
  margin-left: 100px;
  margin-top: -120px;
  gap: 20px;
  margin-bottom: 70px;
  justify-content: center;
}

.H-icon-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 340px;
  height: 225px;
  flex-shrink: 0;
  padding: 15px;
  border: 1px solid #e9e4e4;
  border-radius: 8px;
  background-color: #fff;
  transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
}

.H-icon {
  position: relative; /* Positions the icon relative to the container */
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.H-heading {
  font-size: 20px;
  margin: 0;
  padding: 0; 
  text-align: left;
  color: black;
font-family: Poppins;
font-weight: 700;
word-wrap: break-word

}
.H-icon-container:hover .H-heading {
  color: #fff; /* Change text color to white on hover */
}
.H-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.60);
  font-size: 17px;
  font-family: Poppins;
  font-weight: 400;
  text-align: left;
  word-wrap: break-word;
 
}
.H-icon-container:hover {
  background-color: #3498db; /* Primary color on hover */
  border-color: #2980b9; /* Darker shade of primary color */
  transform: scale(1.05); /* Slightly increase the size */
}
.H-icon-container:hover .H-text {
  color: #fff; /* Change text color to white on hover */
}


  .H-primary-button {
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1em;
    margin-left: 320px;
    width: 250px;
    /* max-width: 12vw; */
     height: 100%;
     transition: background-color 0.3s ease; /* Smooth transition */
      background: linear-gradient(270deg, #096BD9 0%, #4896FF 100%);
       border-radius: 6px;
     border: 2px #6EACFB;
     margin-top: 10px;
  }
  
  .H-primary-button:hover {
    background: linear-gradient(270deg, #e1e4e8 0%, #eef0f3 100%); /* Change gradient on hover */
    animation: expand-shrink-jump 0.6s ease forwards; /* Apply animation */
  color: #096BD9;
  border: #096BD9 2px solid;
  }
    /* Keyframe animation for expanding, shrinking, and jumping */
    @keyframes expand-shrink-jump {
      0% {
          transform: scale(1); /* Initial size */
      }
      30% {
          transform: scaleX(1.3) scaleY(1.3); /* Expand width, shrink height */
      }
      50% {
          transform: scaleX(0.8) scaleY(0.8); /* Shrink width, expand height */
      }
      80% {
          transform: scale(1.1) translateY(-5px); /* Jump effect and expand */
      }
      100% {
          transform: scale(1); /* Return to normal size */
      }
    }
  
  .H-heading2{
    margin-top: 30px;
    font-size: 48px;
font-family: Poppins;
font-weight: 700;
line-height: 83px;
word-wrap: break-word;
  }
  .H-micro-image{
  max-width: 65%; /* Adjust the width of the image */
  height: auto;
  position: absolute;
  top: 7%; /* Adjust this value as needed */
  left: 60vw;;
  }
  .container12, .main-container1, .image-container1 {
    position: relative; /* Ensure parent containers allow free positioning */
    overflow: visible; /* Ensure content is not clipped */
}
  
 
  .head-image{
    background: transparent;
    width: 100%;
    height: auto;
  }
  .image-container1{
    flex: 1;
    z-index: 1; /* Ensure this container is above the background image */
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .my-image {
    width: 100%; /* Make the image fill the container */
    max-width: 100%; /* Keep the image size within a specific limit */
    height: auto; /* Maintain the aspect ratio */
    border-radius: 10px; /* Apply a border radius */
    position: absolute;
    right: 290px;
    top: 20px; /* Adjust positioning as needed */
    /* Add more styles as needed */
  }
   .main-container1 {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the heading, grid, and button horizontally */
    justify-content: flex-start;
    padding: 0;
    margin-top: 0;
  padding-top: 0;


  } 
  .heading2 {
    text-align: center; /* Center the heading text */
    margin-bottom: 10px; /* Add space between heading and containers */
  }
  
  .heading-part1 {
    color: #070707; /* First color */
    font-weight: 700;
    position: relative;
    top: 30px;
  }
  
  .heading-part2 {
    color: #2a4ef0; /* Second color */
    font-weight: 700;
    position: relative;
    top: 30px;
  }
 
  /* Add animation to the buttons */
.Btn-custom, .Btn-custom1 {
  transition: all 0.3s ease;
  margin-left: 200px;
  margin-bottom: 60px;
  margin-top: -45px;

  /* Any additional styling you want for the buttons */
}

/* Hover effect with keyframes */
.animated-btn:hover {
  animation: expand-shrink-jump 0.6s ease forwards;
}
.Btn-custom1:hover {
  background: linear-gradient(270deg, #0b6cea 0%, #4f84d3 100%); /* Change gradient on hover */
  animation: expand-shrink-jump 0.6s ease forwards; /* Apply animation */
color: #ffffff;
border: #ffffff 2px solid;
}
/* Keyframe animation for expanding, shrinking, and jumping */
@keyframes expand-shrink-jump {
  0% {
    transform: scale(1); /* Initial size */
  }
  30% {
    transform: scaleX(1.3) scaleY(1.3); /* Expand width, shrink height */
  }
  50% {
    transform: scaleX(0.8) scaleY(0.8); /* Shrink width, expand height */
  }
  80% {
    transform: scale(1.1) translateY(-5px); /* Jump effect and expand */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

  .center-page {
    display: flex;
    justify-content: center;
  
  }
  .Btn-custom1{
    margin-left: -40px;
    position: absolute;
    margin-top: 50px;
  }
  .Home-image-container2 {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    left: 60px;
    overflow: hidden;
}

.Home-bg-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Ensures the background image stays behind other content */
}

.Home-overlay-content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0 80px;
}

.Home-doctor-image {
    height: auto;
    position: absolute;
    top: 75px;
    left: 180px;
    width: 37%;
}

.HDM-heading-container {
    width: 50%;
    text-align: left;
    position: relative;
    transform: translateX(100px); /* Move heading to the right */
    margin-top: 230px;
}

.HDM-heading3 {
  display: flex;
    font-size: 48px;
    white-space: nowrap;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    color: #000;
    margin-top: -430px; /* Adds space above the heading */
    margin-left: 650px; /* Adds space inside the heading on the left */
}

.heading-part11 {
    color: #070707;
}

.heading-part223 {
    color: #2a4ef0;
    white-space: nowrap;
 margin-left: -440px;
}

.HDM-content-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.HDM-ellipse-line-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.small-line {
    height: 400px;
    stroke: #076AFF;
    stroke-width: 3px;
position: absolute;
top: -30px;

}

.ellipse {
    width: 25px;
    height: 25px;
    margin: 15px 0px;
    background-color: #076AFF;
    border-radius: 50%;
    position: relative;
    top: -20px;
    
}

.HDM-text-container {
  margin-left: 30px;
  padding-top: 10px;
  text-align: left;
  margin-bottom: 50px;
  
}

.bullet-point {
    font-size: 18px;
    font-family: Poppins, sans-serif;
    margin: 28px 0px;
    color: rgba(0, 0, 0, 0.7);
}
  .H-gradient-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 600px; /* Adjust this to the size you need */
    background: linear-gradient(to right, rgba(0, 47, 108, 1), rgba(8, 89, 149, 0.8), rgba(3, 92, 181, 0.804), rgb(42, 126, 236));
    display: flex;
    overflow: hidden;
    margin-top: -20px;
    margin-left: 60px;
  }
  .H-gradient-container img.H-background-image {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  .H-gradient-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    background: inherit;
    z-index: 1;
  }
  .H-container-wrapper {
    position: relative;
    width: 100%;
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto;
    text-align: center;
    left: 5vw;
    margin-top: 0;
    z-index: 2; /* Ensure it's above the background image */
  }

  .H-container-heading {
    font-size: 34px;
    font-weight: bold;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; 
    position: relative;
right: 7vw;
    top: 60px;
    text-align: left;
  }
  .H-container-with-text1, .H-container-with-text2 {
    position: relative;
    margin: 20px;
    width: 45%; /* Adjust width so two containers fit in one row */
    height: 300px; /* Adjust height to your preference */
    background-size: cover;
    background-position: center;
    display: flex;
    border-radius: 15px;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    overflow: hidden;
    color: black;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
  }
  .H-container-with-text1 {
    top: 87px;
    left: -155px;
  }
  .H-container-with-text2 {
    left: 450px;
    bottom: 235px;
  }
  
  .H-text-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .H-text-upper {
    background-color: white;
    padding: 30px;
    flex: 2; /* Increase the size of the upper portion */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    color: black;
font-size: 18px;
font-family: Poppins;
font-weight: 400;
word-wrap: break-word
  }
  
  .H-text-lower {
    background-color: #d9d9d9;
    padding: 20px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 700;
    font-family: poppins;
    font-size: 22px;
  }
  .H-profile-image {
    margin-left: -200px;
  }
   .H-profile-image1 {
    margin-left: -260px;
  }
  .H-Paragraph12{
  margin-left: 20px;
  margin-top: 10px;

  }
  
  
  .container-row {
    display: flex;
    justify-content: space-between; /* Space between two containers */
    flex-wrap: wrap; /* Ensure responsiveness on smaller screens */
  }
  .main-container2 {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 90px; /* Adds space below the container */
    padding-bottom: 90px; /* Adds space inside the bottom of the container */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  
  .center-content {
    text-align: center;
    margin-bottom: 20px;

  }
  .heading22{
    font-size: 48px;
    font-family: Poppins;
font-weight: 700;
line-height: 83px;
word-wrap: break-word
  }
  
  
  .description {
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.60);
    margin-top: 40px;
    font-family: Poppins;
    word-wrap: break-word;
  }
  
  .logo-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* gap: 20px; */
    width: 90vw;
    align-items: center;
  }
  
  .logo-item {
    width: 100%; /* Adjust the size of the boxes */
    height: 100px;
    background: rgba(217, 217, 217, 0);
    border: 2px solid #EFF1F3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  @media (min-width: 1440px) and (max-width: 1910px)
  
  {
    html, body {
      margin: 0;
      padding: 0;
      overflow-x: hidden;
    }
    .H-micro-image{
      max-width: 62%; /* Adjust the width of the image */
      top: 1%; /* Adjust this value as needed */
      left: 65vw;;
      }
    .H-main-container1, .main-container2, .H-gradient-container, .Home-grid {
      max-width: 1600px;
    
    }
    .HDM-heading3{
      margin-left: 600px;
    }
    .HDM-content-container{
      margin-left: 0px;
    }
    .Home-doctor-image{
      top: 30px;
    }
   
  }
 
/* Smartphone screens (max-width: 480px) */
/* @media only screen and (max-width: 480px) {
  .H-content {
    padding: 10px;
    top: 35%;
    left: 45%;
  }
  .H-micro-image{
    width: 127px;
    height: auto;
    margin-left: -10px;
    margin-top: -257px;
    transition: visibility 0.3s ease, opacity 0.3s ease;  */
  /* }
 
.H-primary-button{
  width: 130px;
  height: 25px;
  white-space: nowrap;
  margin-left: 10px;
  position: relative;
  left: 95px;
  padding: initial;
  font-size: 12px;
  top: -321px;


}
  .H-nav-heading {
    font-size: 26px;
    line-height: 30px;
    position: relative;
    top: -270px;
    left: -72px;
  }

  .H-paragraph1 {
    font-size: 10px; */
    /* margin-top: 10px; */
    /* position: relative;
    top: -287px;
    left: -72px;
  }
.dropdown .btn{
  width: 250px;
  height: 35px;
  position: relative;
  top: -295px;
  left: -85px;
  font-size: 12px;
}
  .dropdown-menu {
    width: 80px;
  }
.H-heading2{
  font-size: 35px;
  margin-top: -470px;
  margin-left: -40px;
}
  .H-grid-container {
    grid-template-columns: 1fr;
    padding: 10px;
    margin-top: -120px;
    margin-left: 490px;
  }

  .H-icon-container {
    margin-bottom: 20px;
  }
.Home-image-container2{
  width: 500px;
  margin-left: 370px;
}
  .Home-bg-image{
    width: 440px;
    height: 780px;
  }
   .Home-doctor-image {
    width: 300px;
    height: auto;
    margin-left: -170px;
    margin-top: -40px;
  }
  .HDM-ellipse-line-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;

}

.small-line {
    height: 800px;
position: absolute;
top: 79px;
left: -200px;

}

.ellipse {
    width: 25px;
    height: 25px;
    margin: 15px 0px;
    background-color: #076AFF;
    border-radius: 50%;
    position: relative;
    top: 100px;
left: -212px;    
}

.HDM-text-container {
  margin-left: 30px;
  padding-top: 10px;
  text-align: left;
  margin-bottom: -70px;
  
}

.bullet-point {
    margin: 34px 0px;
 white-space: nowrap;
 font-size: 14px;
 position: relative;
 left: -230px;
 top: 190px;
}

.H-primary-button-2 {
    margin-top: 208px;
    margin-left: -260px;
}

  .HDM-heading-container h1 {
    font-size: 24px;
    line-height: 30px;
  }
  .H-container-wrapper {
 
  }
} */