
/* Flex container for splitting the screen */
.L-split-container {
  display: flex;
  height: 100vh; /* Full viewport height */
  width: 100vw;  /* Full viewport width */
  
}
.L-login-container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    overflow: auto; /* Allow content scrolling within the container */
  }
  
  .L-left-side {
    flex: 1; /* Flex takes up 50% of the space */
    background-color: #f8fcff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    width: 77vw;
    height: 100vh;
  }
  
  .L-logo {
    max-width: 50%; /* Adjust logo size */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .heart-image {
    max-width: 900px;
    width: 100%; /* The image will take up 100% of its container but not exceed 900px */
    height: auto; /* Keeps the aspect ratio */
margin-top: 30px;
  }
  
  .L-logo img {
    width: 60px;
    margin-top: 90px;
    margin-left: -570px;
  }
  
  .L-logo h1 {
    font-size: 36px;
    margin-top: -90px;
    margin-left: 100px;
    font-size: 3.5rem;
    font-weight: bold;
    color: #333;
    white-space: nowrap;
  }

  .L-right-side {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white; /* Transparent background */
  }
  
  .L-right-side h2 {
    font-size: 24px;
  }
  
 .L-role-buttons {
  display: flex;
  gap: 30px;
  margin: 20px 0;
}

.L-role-btn {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 120px;
  border-radius: 20px;
  border: #0f0f0f solid 1px;
  background-color: transparent;
  transition: background-color 0.3s ease; /* Add transition for background color */
}

.admin {
  color: #160af5;
}

.doctor {
  color: #dc3545;
}

.patient {
  color: #6c63ff;
}

/* Animation styles */
.animated-btn1:hover {
  animation: expand-shrink-jump 0.6s ease forwards;
  background-color: #3a86ff;
  color: #ffffff;
}

/* Keyframe animation for expanding, shrinking, and jumping */
@keyframes expand-shrink-jump {
  0% {
    transform: scale(1); /* Initial size */
  }
  30% {
    transform: scaleX(1.3) scaleY(1.3); /* Expand width, shrink height */
  }
  50% {
    transform: scaleX(0.8) scaleY(0.8); /* Shrink width, expand height */
  }
  80% {
    transform: scale(1.1) translateY(-5px); /* Jump effect and expand */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

  .L-login-form {
    display: flex;
    flex-direction: column;
  }
  .L-right-side h1 {
    font-size: 38px;
    font-weight: 700;
    margin-left: -30px;
  }
  .L-form-group {
    margin-bottom: 15px;
    width: 400px;
  }
  
  .L-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    margin-left: -280px;
  }
  .L-input-container {
    position: relative; /* Positioning for the icon */
  }
  
  .L-form-group input {
    width: 100%;
    padding: 10px 10px 10px 20px; /* Padding: top, right, bottom, left */
    border: 1px solid #ccc;
    border-radius: 30px;
   
  }
  input::placeholder {
    color: #aaa; /* Placeholder color */
    font-size: 16px;
    opacity: 1; /* Ensure placeholder is fully opaque */
  }
  .L-icon {
    position: absolute; /* Positioning the icon */
    right: 10px; /* Space from the right */
    top: 50%; /* Center vertically */
    font-size: 30px;
    transform: translateY(-50%); /* Adjust for vertical centering */
    cursor: pointer; /* Change cursor to pointer on hover */
  }
  .L-icon.hidden {
    /* text-decoration:line-through; Strikethrough effect */
    color: red; /* Optional: Change color for visibility */
  }
  
.L-icon.hidden::after {
  content: '';
  position: absolute;
  width: 45px; /* Width of the diagonal line */
  height: 3px; /* Thickness of the diagonal line */
  background-color: red; /* Color of the diagonal line */
  top: 48%; /* Center vertically */
  left: -2px; /* Adjust left position as necessary */
  transform: rotate(135deg); /* Rotate to create a diagonal line */
}

/* Optional: Adjust opacity of the eye icon when hidden */
.L-icon.hidden {
  opacity: 2.5; /* Optional: make the icon slightly transparent */
}
  .L-mandatory {
    color: red; /* Color for the mandatory sign */
  }
  .L-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .L-custom-checkbox {
    width: 23px;
    height: 23px;
    border: 2px solid #ccc;
    border-radius: 4px;
    appearance: none; /* Remove the default checkbox styling */
    cursor: pointer;
    position: relative;
    top: 5px;
    display: inline-block;
  }
  
  .L-custom-checkbox:checked {
    /* background-color: #4CAF50;
    border-color: #4CAF50; */

  }
  
  .L-custom-checkbox:checked::after {
    content: '✔'; /* Unicode for tick mark */
    color: rgb(73, 39, 211);
    font-size: 18px;
    position: absolute;
    left: 2px;
    margin-top: -2px;
    top: 0;
  }
  
  .L-login-btn {
    padding: 12px;
    background-color: #3a86ff;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-radius: 20px;
  transition: background-color 0.3s ease; /* Add transition for background color */
position: relatives;  
}
  .animated-btn2:hover {
    animation: expand-shrink-jump 0.6s ease forwards;
    background-color: #ffffff;
    color:#3a86ff ;
    border:2px solid #3a86ff ;
  }
  
  /* Keyframe animation for expanding, shrinking, and jumping */
  @keyframes expand-shrink-jump {
    0% {
      transform: scale(1); /* Initial size */
    }
    30% {
      transform: scaleX(1.3) scaleY(1.3); /* Expand width, shrink height */
    }
    50% {
      transform: scaleX(0.8) scaleY(0.8); /* Shrink width, expand height */
    }
    80% {
      transform: scale(1.1) translateY(-5px); /* Jump effect and expand */
    }
    100% {
      transform: scale(1); /* Return to normal size */
    }
  }
  .L-social-login {
    text-align: center;
    margin-top: 20px;
  }
  
  .L-social-login p {
    margin-bottom: 10px;
  }
  
  .L-social-icons {
    display: flex;
    gap: 10px;
    margin-left: 110px;
  }
  .L-icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Square size */
    height: 40px; /* Square size */
    border: 1px solid #ccc; /* Border color */
    border-radius: 8px; /* For slightly rounded edges, remove if you want a sharp square */
    cursor: pointer;
    transition: border-color 0.3s ease, background-color 0.3s ease;
  }
  
  .L-icon-wrapper:hover {
    border-color: black; /* Border color on hover */
    color: #ffffff;
    background-color: #9fb2e3; /* Background color on hover */
  }
  
  .L-icon-wrapper svg {
    font-size: 20px; /* Adjust the icon size */
    color: #333; /* Icon color */
  }
  
  .L-social-icons svg {
    font-size: 24px;

    cursor: pointer;
  }
  @media (min-width: 1440px) and (max-width: 1910px) {
    .L-split-container {

    }
   
    .L-left-side {
   height: 130vh;
    }
    .L-logo img{
    margin-left: -460px;
    }
    .L-logo h1 {

      font-size: 3rem;
      white-space: nowrap;
    }
    .heart-image {

    }
    .L-right-side {

 
    }
  }