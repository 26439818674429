/* General Footer Styles */
html, body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}
body {
  margin: 0;
  padding: 0;
  overflow-y: hidden; /* Ensures only one scrollbar */
}

.footer {
  background: linear-gradient(to left, #011245 0%, #0085FF 100%);
  color: white;
  font-family: Arial, sans-serif;
  padding: 20px;
  position: relative;
  width: 100%;
  min-height: 13vh; /* Ensures the container fits within the viewport */
    overflow: hidden; /* Prevents internal scrolling */
  z-index: 1; /* Make sure it is higher than any overlay content */
}
  .footer-top{
    max-width: 1200px; 
    width: 80%; 
    margin: 0 auto; 
    padding: 20px;
  }
  .footer-top, .footer-bottom {
    overflow: visible;
  }
  .footer-content, .footer-links {
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap; 
    justify-content: space-between; 
  }
  
  footer.footer-content {
    max-width: 1200px !important;
    width: 100% !important;
    margin: 0 auto !important;
  }
  .footer-bottom {
    min-height: 185px;
  }
  
  
  .footer-buttons {
    display: flex;
    justify-content: space-between;
    gap: 80px; 
    position: relative; 
    z-index: 1; 
    margin-bottom: 40px;
    margin-right: 130px;
  } 
.footer-top {
  background-color: white;
  color: #0f3d7f; 
  padding: 20px;
  display: flex;
  justify-content: space-between; 
  align-items: center; 
 
  margin: -100px auto 0 auto; 
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 170px;
  z-index: 0;
  text-decoration: none;
}
.footer-main {
  /* background: linear-gradient(to left, #011245 0%, #0085FF 100%); */
  position: relative;
  padding: 20px; /* Adjust padding as needed */
  z-index: 1;
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  width: 100%; /* Ensure the content doesn't overflow */
  margin: 0 auto; /* Center the content */
  height: 320px; /* Adjust this value to your preference */
  box-sizing: border-box; /* Ensures padding and height play nicely together */
}

 .footer-top-heading {
  margin: 50px;
  color: black;
  font-size: 48px;
  font-family: Outfit;
  font-weight: 700;
  line-height: 55px;
} 


.footer-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:  #076AFF;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: rgba(0, 0, 0, 0.52); 
  white-space: nowrap;
  transition: background-color 0.3s;
  width: 70px; 
  height: 60px;
  text-decoration: none; 
} 

.footer-button:hover {
  background-color: #0f3d7f;
  color: rgba(0, 0, 0, 0.52);
}
.footer-button:hover .footer-button-text {
  text-decoration: underline; 
  text-underline-offset: 5px;
} 
 .footer-icon-container {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
} 

.footer-icon {
  width: 100%;
  height: 100%;
  fill: #0f3d7f; 
} 

 .footer-button-text {
  color: rgba(0, 0, 0, 0.52);
  font-size: 18px;
  font-family: Outfit;
  font-weight: 500;
  line-height: 55px;
  text-decoration: none; 
} 

.footer-button span:hover {
  text-decoration: underline; 
  text-underline-offset: 5px; 
}
.footer-content {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.company-info {
  width: 30%;
  text-align: center; /* Center-align the company info content */
  margin-top: 30px;
}

.company-logo-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: -560px;
  cursor: pointer; /* Ensure the pointer changes to indicate a clickable item */
  text-decoration: none; /* Removes underline from link */
  color: inherit; /* Inherits text color */
}

.company-logo {
  width: 50px;
  height: auto;

  /* margin-right: 10px; */
}

.company-name {
  font-size: 24px;
  color: white;
  margin: 0;
}
.social-icons {
  display: flex;
  /* justify-content: center;
  margin-top: 10px; */
  gap: 10px; /* Add space between social media icons */
  margin-left: -240px;
  margin-top: 35px;
}

.social-logo {
  width: 50px; /* Adjust size as needed */
  height: 50px;
  border-radius: 50%; /* Make the container circular */
  background-color: white; /* White background for the circle */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Optional: Add a border to make the circle more defined */
  border: 2px solid #ddd; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}
.social-logo img {
  width: 20px; /* Adjust the size of the icon */
  height: auto; /* Adjust the size of the icon */
  object-fit: contain; /* Ensures the icon scales correctly within the circle */
}

.footer-links {
  display: flex;
  justify-content: space-between;
  width: 60%;
  text-align: left;
  margin-right: 120px;
  gap: 100px;
  color: white;
font-size: 22px;
margin-top: -200px;
font-family: Poppins;
font-weight: 700;
position: relative;
z-index: 1; /* Ensure this is higher than z-index of images */

}
.services{
  margin-left: 190px;

}
.legal-notice{
  margin-top: -415px;
  margin-left: 500px;
}
.explore{
  margin-left: 750px;
  margin-top: -515px;
   
 }

.footer-links div {
  width: 30%;
}

.footer-links h3 {
  margin-top: 45px;
  color: white;
font-size: 25px;
font-family: Poppins;
font-weight: 800;
margin-bottom: 20px;
}


.footer-links ul {
  list-style-type: none;
  padding: 0;
  color: white;
font-size: 19px;
font-family: Poppins;
font-weight: 500;
word-wrap: break-word
}


.footer-links ul li a {
  color: #cfd9e6; /* Light gray for text */
  text-decoration: none;
  margin-top: 12px;
  display: flex;
  display: inline-block; /* Ensures the underline matches the text length */
  align-content: space-around;
  position: relative; /* Required for the custom underline positioning */
}

.footer-links ul li a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px; /* Adjust the position below the text */
  width: 100%; /* Matches the text width dynamically */
  height: 3px; /* Thickness of the underline */
  background-color: currentColor; /* Uses the text color */
  transform: scaleX(0); /* Hidden initially */
  transform-origin: left; /* Starts from the left */
  transition: transform 0.3s ease; /* Smooth reveal */
}

.footer-links ul li a:hover::after {
  transform: scaleX(1); /* Reveal the underline on hover */

}

.footer-images-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 0;
}
.back-image1,
.back-image2,
.back-image3,
.back-image5 {
  pointer-events: none;
}


/* Image 1 */
.back-image1 {
  width: 750px;
  height: 330px;
 position: absolute;
 bottom: -510px;
    filter: contrast(100%);
}


.back-image2 {
  position: absolute; 
  width: 250px;
  height: 319px;
bottom: -320px;
margin-left: 520px;
  filter: contrast(150%);
  pointer-events: none; 
}

/* Image 3 */
.back-image3 {
  position: absolute; /* Positioned relative to .footer-main */
  width: 211px;
  height: 191px;
  bottom: -290px;
  margin-left: 600px;
  filter: contrast(250%);
  pointer-events: none; /* Prevent interaction */
}

/* Image 4 */
.back-image5 {
  position: absolute;
  width: 211px;
  height: 191px;
  margin-top: 320px;
  margin-left: 745px;
    filter: contrast(1550%);
}


.footer-bottom {
  text-align: center;
  padding: 10px 0;
  background: linear-gradient(to left, #011245 0%, #0085FF 100%);
  color: white;
 
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  margin-top: -60px;
  
}

.footer-line {
  border: none;
  border-top: 2px solid white;
/* margin-top:140px; */
  width: 100%;
  position: relative;
  top: 120px;
  /* margin: 110px auto 20px auto;  */
  opacity: 1.50;

}
.footer-bottom p {
  color: white;
  font-size: 13px;
  font-family: Poppins;
  font-weight: 400;
  position: relative;
  top: 115px;
  line-height: 1.5; /* Adjusts text spacing */
  /* line-height: 24.78px; */
  /* margin-top: 10px; */
}

@media (min-width: 1440px) and (max-width: 1910px) {
  /* .footer-buttons{
margin-right: 32px;
  } */
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .company-logo{
    margin-left: 100px;
  }
  .social-icons {
  margin-left: -195px;
  }
  .back-image2{
    margin-left: 450px;
  }
  .back-image3{
    margin-left: 530px;
  }
  .back-image5 {
    position: absolute;
    margin-top: 325px;
    margin-left: 545px;
      filter: contrast(1550%);
  }
 
}